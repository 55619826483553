<template>
  <EditUserLayout v-model="newUser.name" show-delete :user="newUser" @go-back="$emit('go-back')" @update:user="updatePH">
    <template #left-column>
      <p class="editPhotographer__title">{{ $t('GENERAL_INFORMATION') }}</p>
      <BaseInput v-model="newUser.email" disabled class="fs-12" :label="$t('PERSONAL_DATA.EMAIL')" />
      <BaseInput v-model="newUser.phone" class="fs-12" :label="$t('PERSONAL_DATA.PHONE')" />
      <BaseCalendarInput v-model="newUser.birthdate" use-age-filter class="fs-12" :label="$t('PROFILE.DATE_OF_BIRTH')" />
      <BaseLocation v-model="newUser.main_location" class="fs-12 mt-20 pa-10" :placeholder="$t('PERSONAL_DATA.LOCATION')" />
      <p class="editPhotographer__title editPhotographer__title__language mt-20">{{ $t('LANGUAGE') }}</p>
      <BaseSelect v-model="newUser.english_level" class="fs-12" :clearable="false" :options="languageLevelOptions" :label="$t('PROFILE.ENGLISH_LEVEL')" />
      <BaseSelect v-model="newUser.arabic_level" class="fs-12" :clearable="false" :options="languageLevelOptions" :label="$t('PROFILE.ARABIC_LEVEL')" />
    </template>
    <template #middle-column>
      <p class="editPhotographer__title">{{ $t('EQUIPMENT') }}</p>
      <ManageEquipment :equipment="equipment" :user="newUser" @equipment:update="handleEquipmentUpdate" />
    </template>
    <template #right-column>
      <p class="editPhotographer__title editPhotographer__title__experience">{{ $tc('EXPERIENCE', 0) }}</p>
      <BaseInput v-if="newUser.details && newUser.details.portfolio_link" v-model="newUser.details.portfolio_link" class="fs-12" :label="$t('PROFILE.PORTFOLIO_LINK')" />
      <BaseToggle v-model="newUser.is_pro" class="full-width mt-32" label-position="left">{{ $t('PHOTOGRAPHER_PLUS_PACKAGE') }}</BaseToggle>
      <BaseToggle v-model="newUser.is_internal" class="full-width mt-32" label-position="left">{{ $t('PHOTOGRAPHER_INTERNAL') }}</BaseToggle>
      <hr data-theme="light" class="hr" />
      <div class="align-middle justify-between mb-20 mt-40">
        <p class="editPhotographer__title mb-0">{{ $t('SPECIALIZATION') }}</p>
        <BaseIcon
          icon="plus"
          :custom_style="{ cursor: 'pointer', height: '11px', 'min-height': '14px', 'min-width': '14px', 'stroke-width': '3px', width: '11px' }"
          @click.native="showSpecializationDialog = true"
        />
      </div>
      <div v-for="service of specializations" :key="`${service.service_id}__spec`" class="editPhotographer__specialization">
        <span>{{ serviceName(service.service_id) }}</span>
        <BaseSlider class="editPhotographer__slider" :value="service.rate" @value:update="handleSpecializationUpdate({ service: service.service_id, value: $event })" />
        <BaseIcon
          class="ml-5"
          icon="close"
          :custom_style="{ cursor: 'pointer', height: '11px', 'min-height': '14px', 'min-width': '14px', 'stroke-width': '3px', width: '11px' }"
          @click.native="removeSpecialization(service.service_id)"
        />
      </div>
      <AddSpecialization
        :show="showSpecializationDialog"
        :specializations="userSpecializations"
        :title="`${newUser.photographer_id} ${newUser.name}`"
        @dialog:closed="showSpecializationDialog = false"
        @specializations:update="addSpecialization"
      />
    </template>
    <template #footer-actions>
      <BaseButton class="mr-15" :icon="blockIcon" :icon_style="{ height: '16px', width: '16px' }" :label="blockLabel" type="info" @click.native="handleBlockUnlock" />
    </template>
  </EditUserLayout>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

// Api

// Base Components
import BaseLocation from '@base/BaseLocation.vue';
import BaseButton from '@base/BaseButton.vue';
import BaseCalendarInput from '@base/BaseCalendarInput.vue';
import BaseIcon from '@base/BaseIcon.vue';
import BaseInput from '@base/BaseInput.vue';
import BaseSlider from '@base/BaseSlider.vue';
import BaseSelect from '@base/BaseSelect.vue';
import BaseToggle from '@base/BaseToggle.vue';

// Common Components
import EditUserLayout from '@components/accounts/EditUserLayout.vue';

// Components
import AddSpecialization from '@components/accounts/AddSpecialization.vue';
import ManageEquipment from '@components/photographer/ManageEquipment.vue';

/**
 * Component used to edit a user
 */
export default {
  name: 'EditPhotographer',
  components: {
    AddSpecialization,
    BaseButton,
    BaseCalendarInput,
    BaseIcon,
    BaseInput,
    BaseLocation,
    BaseSlider,
    BaseSelect,
    BaseToggle,
    EditUserLayout,
    ManageEquipment
  },
  props: {
    /**
     * get the selected user Id
     */
    selectedUserId: { default: null, type: Number },
    /**
     * User to be edited
     */
    user: { default: () => ({}), type: Object }
  },
  data() {
    return {
      languageLevelOptions: [
        this.$t('LANGUAGE_LEVEL.NATIVE'),
        this.$t('LANGUAGE_LEVEL.FLUENT'),
        this.$t('LANGUAGE_LEVEL.INTERMEDIATE'),
        this.$t('LANGUAGE_LEVEL.BEGINNER'),
        this.$t('LANGUAGE_LEVEL.NONE')
      ],
      newUser: {},
      selectedCategory: null,
      showSpecializationDialog: false
    };
  },
  computed: {
    ...mapGetters({
      services: 'services/getServices'
    }),
    /**
     * Returning block button icon
     */
    blockIcon() {
      return this.isBlocked ? 'unlock' : 'lock';
    },
    /**
     * Returning the block button label.
     * If user is already blocked the label will be unlock
     */
    blockLabel() {
      return this.isBlocked ? this.$t('UNLOCK') : this.$t('BLOCK');
    },
    /**
     * Return user equipment
     */
    equipment() {
      // Retrieving details from  user object (fallback to empty object if undefined)
      const { details = {} } = this.newUser;
      // Retrieving equipment from details (fallback to empty object if undefined)
      const { equipment = {} } = details;

      return equipment;
    },
    /**
     * Check if the user is blocked
     */
    isBlocked() {
      return this.newUser.status === 'blocked';
    },
    /**
     * Selected equipment items
     */
    items() {
      if (!this.selectedCategory) return [];

      return this.newUser.details.equipment[this.selectedCategory];
    },
    /**
     * Returnt the user specializations
     */
    specializations() {
      return this.newUser.services || [];
    },
    /**
     * Returning user specializations
     */
    userSpecializations() {
      return (this.specializations && this.specializations.map(({ service_id }) => service_id)) || [];
    }
  },
  created() {
    this.newUser = Object.assign({}, this.user);
  },
  methods: {
    /**
     * Callback used to add one or more specializations
     */
    addSpecialization($event) {
      const services = [...this.newUser.services];

      for (const spec of $event) {
        services.push({
          service_id: spec,
          rate: 5
        });
      }

      this.$set(this.newUser, 'services', services);
    },
    /**
     * Method used to block or unlock user
     */
    handleBlockUnlock() {
      const newStatus = this.newUser.status === 'blocked' ? 'active' : 'blocked';

      this.$set(this.newUser, 'status', newStatus);

      this.updatePH();
    },
    /**
     * Method used to update the equipments
     */
    handleEquipmentUpdate({ selectedCategory, value }) {
      this.$set(this.newUser.details.equipment, selectedCategory, value);
    },
    /**
     * Method used to update and save on BE the new user info
     */
    async updatePH() {
      this.$emit('update-user', this.newUser.id, this.newUser);
    },
    /**
     * Method used to update a specialization value
     */
    handleSpecializationUpdate({ service, value }) {
      const serviceToUpdate = this.newUser.services.find(({ service_id }) => +service_id === +service);

      serviceToUpdate.rate = value;
    },
    /**
     * Method used to remove one specifc service from the specializations list
     */
    removeSpecialization(id) {
      const serviceIndex = this.newUser.services.findIndex(({ service_id }) => +service_id === +id);
      const services = [...this.newUser.services];

      services.splice(serviceIndex, 1);

      this.$set(this.newUser, 'services', services);
    },
    /**
     * Returning service name based on id
     */
    serviceName(_id) {
      const service = this.services.find(({ id }) => +id === +_id) || {};

      return service.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.editPhotographer {
  &__equipment {
    &-box {
      background-color: var(--terziary-text-color);
      border-radius: 10px;
      padding: 11px;
      width: 316px;

      ~ .editPhotographer__equipment-box {
        margin-top: 10px;
      }
    }

    &-items {
      column-gap: 11px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      row-gap: 11px;
    }

    &-tag {
      align-items: center;
      background-color: var(--main-bg-color);
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      padding: 9px 12px;
      text-align: left;

      > p {
        @include inter-font($size: 14px);
        @include text-ellipsed;
        margin: 0;
      }
    }

    &-title {
      @include inter-font($size: 14px);
      text-align: left;
    }
  }

  &__slider {
    width: 180px;
  }

  &__specialization {
    align-items: center;
    background-color: var(--terziary-text-color);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    height: 35px;
    padding: 9px 12px;
    width: 100%;

    > span {
      @include inter-font($size: 14px);
      @include text-ellipsed;
      margin-right: 15px;
      text-align: left;
      width: 130px;
    }

    & ~ .editPhotographer__specialization {
      margin-top: 15px;
    }
  }

  &__title {
    @include inter-font($size: 16px, $bolded: true);
    text-align: left;
    &__language,
    &__experience {
      @include responsive($max: md) {
        padding-top: 20px;
      }
    }
  }
}
</style>
