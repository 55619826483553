<template>
  <q-dialog ref="dialog" @hide="handleClose">
    <q-card class="deleteUser__card">
      <q-card-section class="row items-center pa-0">
        <p class="deleteUser__title">{{ user.client_id || user.photographer_id || '-' }} {{ user.name || '-' }}</p>
        <q-space />
        <BaseIcon icon="close" v-close-popup class="deleteUser__close" />
      </q-card-section>
      <hr class="deleteUser__hr" />

      <q-card-section class="pa-0 mt-36 deleteUser__section">
        <div class="deleteUser__body">
          <p class="deleteUser__body-title">{{ $t('DELETE_USER', { role: user.role }) }}</p>
          <p class="deleteUser__body-description">{{ $t('DELETE_USER_DETAIL') }}</p>
          <BaseInput v-model="id" :label="$t('DELETE_USER_ID', { role: user.role })" />
        </div>
        <hr data-theme="extra-light" class="hr mt-34" />
        <div class="deleteUser__footer mt-20">
          <BaseButton :label="$t('GO_BACK')" @click.native="goBack" />
          <BaseButton type="default" :disabled="isConfirmDisabled" :label="$t('CONFIRM')" @click.native="$emit('user:delete')" />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Base Components
import BaseButton from '@base/BaseButton.vue';
import BaseIcon from '@base/BaseIcon.vue';
import BaseInput from '@base/BaseInput.vue';

/**
 * Component that shows a dialog with functionality to select a new equipment for the user
 */
export default {
  name: 'DeleteUser',
  components: {
    BaseButton,
    BaseIcon,
    BaseInput
  },
  props: {
    /**
     * Set to true to show the dialog
     */
    show: { default: false, type: Boolean },
    /**
     * User
     */
    user: { default: () => ({}), type: Object }
  },
  data() {
    return {
      id: null
    };
  },
  computed: {
    /**
     * Returns true if user id is wrong
     */
    isConfirmDisabled() {
      return this.user.role === 'client' ? +this.id !== this.user.client_id : +this.id !== this.user.photographer_id;
    }
  },
  watch: {
    /**
     * When true show the dilaog, when false hide it
     */
    show() {
      if (this.show) {
        this.$refs.dialog.show();
      } else {
        this.$refs.dialog.hide();
      }
    }
  },
  methods: {
    /**
     * Method used to add a specialization
     */
    deleteUser(id) {
      this.newItems.push(id);
    },
    /**
     * Method used to return to the account selected
     */
    goBack() {
      this.handleClose();
    },
    /**
     * Method used to handle the closing of the dialog by emitting an event
     */
    handleClose() {
      this.$emit('dialog:closed');
    }
  }
};
</script>

<style lang="scss">
.q-dialog__inner--minimized {
  @include responsive($max: md) {
    padding: 0 !important;
  }
}
.deleteUser {
  &__body {
    display: flex;
    flex-direction: column;
    > section {
      flex: 50%;
    }

    &-description {
      @include inter-font($size: 14px);
    }

    &-title {
      @include antonio-font($size: 23px);
    }
  }

  &__card {
    border-radius: 10px;
    padding: 35px 40px;
    min-width: 670px;
    @include responsive($max: md) {
      max-height: 100% !important;
      min-width: 0;
    }
  }
  &__close {
    @include responsive($max: md) {
      display: none;
    }
  }

  &__footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    @include responsive($max: md) {
      justify-content: space-between;
    }
  }
  &__hr {
    border: 1px solid var(--main-bg-color);
  }

  &__title {
    @include antonio-font($size: 14px);
  }
  &__section {
    @include responsive($max: md) {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 144px);
      justify-content: space-between;
    }
  }
}
</style>
