<template>
  <BaseTabs :active-tab-index="activeTabIndex" :tabs="tabs" @changed:tab="handleChangedTab" v-if="!editComponent">
    <template #CREATORS v-if="activeTab === 'CREATORS'">
      <div class="container">
        <div class="pagination-container">
          <q-pagination v-model="currentPage" :max="totalPages" :ellipses="false" :max-pages="15" color="black" />
          <span>{{ total }} Creators</span>
        </div>
        <div class="filters-container">
          <q-input v-model.trim="searchQuery" label="Search creators" @input="handleSearchInput" @keypress="onlyAllowLettersAndSpaces" outlined style="margin-bottom: 6px">
            <template v-slot:append>
              <q-icon name="search" color="primary" @click="updateFilters" class="cursor-pointer" />
            </template>
          </q-input>
          <q-select v-model="selectedServices" :options="serviceOptions" label="Filter by service" multiple use-chips @input="updateFilters" />
          <q-select v-model="selectedLocations" :options="locationOptions" label="Filter by location" multiple use-chips @input="updateFilters" :loading="isLoadingLocations" />
          <q-select v-model="selectedStatuses" :options="statusOptions" label="Filter by status" multiple use-chips @input="updateFilters" />

          <q-checkbox v-model="contentType" val="videography" label="Videography" @input="updateFilters" style="margin-top: 18px" />
          <q-checkbox v-model="contentType" val="photography" label="Photography" @input="updateFilters" />
        </div>
        <div class="users-container">
          <div v-for="user in users" class="user-card" v-bind:key="user.id">
            <div class="total-shoots">
              <q-icon name="camera_alt" size="sm" color="primary" />
              <span style="margin-left: 6px">{{ user.total_shoots }}</span>
            </div>
            <p class="user-name">{{ user.name }}</p>
            <div class="user-location">
              <q-icon name="location_on" size="xs" color="grey-7" />
              <span>{{ user.main_location }}</span>
            </div>
            <div class="chips-container">
              <q-chip v-for="service in getTopSixServices(user.shoots_per_service)" :key="service.id" outline color="#151515" text-color="#151515">
                {{ service.service_name }} {{ service.count }}
              </q-chip>
            </div>
            <div class="user-info">
              <div class="info-item">
                <q-icon name="phone" size="xs" color="grey-7" />
                <span>{{ user.phone }}</span>
              </div>
              <div class="info-item">
                <q-icon name="link" size="xs" color="grey-7" />
                <a target="_blank" style="text-decoration: none; color: #666666" :href="user.details.portfolio_link || '#'">Portfolio link</a>
              </div>
              <div class="info-item">
                <q-icon name="email" size="xs" color="grey-7" />
                <span>{{ user.email }}</span>
              </div>
            </div>

            <div class="card-footer">
              <div style="margin-right: auto; display: flex; align-items: center">
                <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]"> Status </q-tooltip>
                <div :class="['status-indicator', `bg-${getStatusColor(user.status)}`]"></div>
                <span class="q-ml-sm" style="margin-left: 10px">{{ capitalizeFirstLetter(user.status) }}</span>
              </div>
              <div style="margin-right: 4px">
                <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]"> Edit user </q-tooltip>
                <q-btn flat round textColor="gray-7" icon="edit" :disabled="user.status === 'deleted'" @click="editUser(user)" size="sm" />
              </div>

              <div style="margin-right: 4px">
                <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]"> Soft delete user </q-tooltip>
                <q-btn flat round icon="delete" textColor="grey-7" :disabled="user.status === 'deleted'" @click="updateUser(user.id, { ...user, status: 'deleted' })" size="sm" />
              </div>

              <div>
                <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
                  {{ getTooltipText(user) }}
                </q-tooltip>
                <q-btn flat size="sm" round textColor="grey-7" :icon="getButtonIcon(user)" @click="updateUser(user.id, { ...user, status: getNewStatus(user) })" :disabled="isDisabled(user)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #CUSTOMERS v-if="activeTab === 'CUSTOMERS' && users">
      <div class="container">
        <div class="filters-container">
          <q-input v-model.trim="searchQuery" label="Search customers" @input="handleSearchInput" @keypress="onlyAllowLettersAndSpaces" outlined style="margin-bottom: 6px">
            <template v-slot:append>
              <q-icon name="search" color="primary" @click="updateFilters" class="cursor-pointer" />
            </template>
          </q-input>
          <q-select v-model="selectedLocations" :options="locationOptions" label="Filter by location" multiple use-chips @input="updateFilters" :loading="isLoadingLocations" />
          <q-select v-model="selectedStatuses" :options="statusOptions" label="Filter by status" multiple use-chips @input="updateFilters" />
          <div v-if="revenueRange" class="revenue-range-filter">
            <p style="margin: 0; text-align: left; color: #666666; margin-top: 12px; margin-bottom: 3px">Filter by revenue</p>
            <q-range
              v-model="revenueRangeModel"
              :min="parseInt(revenueRange.low)"
              :max="parseInt(revenueRange.high)"
              :step="1"
              color="primary"
              @input="handleRevenueRangeInput"
              :loading="isLoadingRange"
            />
            <div class="range-labels">
              <span>{{ revenueRangeModel.min }} AED</span>
              <span>{{ revenueRangeModel.max }} AED</span>
            </div>
          </div>
        </div>

        <CustomersTable
          :customers="users"
          :isLoading="isLoading || isRefetching"
          :capitalizeFirstLetter="capitalizeFirstLetter"
          @edit-user="editUser"
          @update-user-status="updateUser"
          :totalPages="totalPages"
        />
      </div>
    </template>

    <!-- Rest of the existing template code -->
  </BaseTabs>

  <component v-else :is="editComponent" :user="selectedUser" :selectedUserId="Number(selectedUserId)" @update-user="updateUser" @go-back="goBack" />
</template>

<script>
import { ref, computed, watch, onUnmounted } from '@vue/composition-api';
import { useQuery, useQueryClient, useMutation } from '@tanstack/vue-query';
import BaseTabs from '@base/BaseTabs.vue';
import { UserApi } from '@api/index';
import { debounce } from 'lodash';

import CustomersTable from './CustomersTable.vue';
// Editing components
import EditClient from '@components/accounts/EditClient.vue';
import EditPhotographer from '@components/accounts/EditPhotographer.vue';

const fetchUsers = async (page, filters, activeTab) => {
  let newFilters = { ...filters };
  if (activeTab === 'CUSTOMERS') {
    newFilters.role = 'client';
  } else {
    newFilters.role = 'photographer';
  }

  const response = await UserApi.searchUsers({
    payload: {
      filters: newFilters,
      pagination: {
        offset: (page - 1) * 50,
        size: 50
      }
    }
  });

  return {
    users: response.data.users,
    total: response.data.total
  };
};
const fetchLocations = async activeTab => {
  let targetRole;
  if (activeTab === 'CUSTOMERS') {
    targetRole = 'client';
  } else {
    targetRole = 'photographer';
  }

  const locations = await UserApi.getUniqueLocations(targetRole);

  return {
    locations
  };
};

export default {
  name: 'Accounts',
  components: {
    BaseTabs,
    EditPhotographer,
    CustomersTable,
    EditClient
  },
  setup(props, { root }) {
    const store = root.$store;
    const activeTab = ref('CREATORS');
    const activeTabIndex = ref(0);
    const page = ref(1);
    const searchQuery = ref('');
    const selectedServices = ref([]);
    const selectedLocations = ref([]);
    const selectedStatuses = ref([]);
    const contentType = ref([]); // Initialize as an empty array
    const editComponent = ref(null);
    const selectedUser = ref(null);
    const selectedUserId = ref(0);
    const queryClient = useQueryClient();
    const revenueRangeModel = ref(null);

    const services = computed(() => store.getters['services/getServices']);
    const serviceOptions = computed(() => services.value.map(service => ({ label: service.name, value: service.id })));

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Blocked', value: 'blocked' },
      { label: 'Waiting', value: 'waiting' },
      { label: 'Deleted', value: 'deleted' }
    ];

    const tabs = computed(() => [
      { label: 'CREATORS', value: 'CREATORS' },
      { label: 'CUSTOMERS', value: 'CUSTOMERS' }
    ]);

    const filters = ref({
      statuses: ['active', 'blocked', 'waiting', 'deleted'],
      role: 'photographer'
    });

    const updateFilters = async () => {
      const newFilters = {
        role: 'photographer'
      };

      if (searchQuery.value.trim() !== '') {
        newFilters.searchQuery = searchQuery.value.trim();
      }

      if (selectedServices.value.length > 0) {
        const services = await selectedServices.value.map(srv => srv.value);
        newFilters.services = services;
      }

      if (selectedLocations.value.length > 0) {
        const locations = await selectedLocations.value.map(location => location.value);
        newFilters.locations = locations;
      }

      if (contentType.value.length > 0) {
        newFilters.contentType = contentType.value;
      }

      if (selectedStatuses.value.length > 0) {
        const statuses = await selectedStatuses.value.map(status => status.label.toLowerCase());
        newFilters.statuses = statuses;
      } else {
        newFilters.statuses = ['active', 'blocked', 'waiting', 'deleted'];
      }
      // Add revenue range to filters
      if (revenueRangeModel.value && (revenueRangeModel.value.min > parseInt(revenueRange.value.low) || revenueRangeModel.value.max < parseInt(revenueRange.value.high))) {
        newFilters.revenueRange = {
          min: revenueRangeModel.value.min,
          max: revenueRangeModel.value.max
        };
      }

      filters.value = newFilters;
    };

    const {
      data: usersData,
      isLoading,
      isRefetching
    } = useQuery({
      queryKey: ['users', page, activeTab, filters],
      queryFn: () => fetchUsers(page.value, filters.value, activeTab.value),
      keepPreviousData: true,
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 60 * 24 // 24 hours
    });

    const users = computed(() => usersData.value?.users || []);
    const total = computed(() => usersData.value?.total || 0);
    const totalPages = computed(() => Math.ceil(total.value / 50));

    const { data: uniqueLocationsData, isLoading: isLoadingLocations } = useQuery({
      queryKey: ['uniqueLocations', activeTab],
      queryFn: () => fetchLocations(activeTab.value),
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24
    });

    const locationOptions = computed(() => {
      if (!uniqueLocationsData.value) return [];
      return uniqueLocationsData.value.locations.map(location => ({ label: location, value: location }));
    });

    const { data: revenueRange, isLoading: isLoadingRange } = useQuery({
      queryKey: ['revenueRange'],
      queryFn: UserApi.getRevenueRange,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60 * 24
    });

    watch(
      () => revenueRange.value,
      newRevenueRange => {
        if (newRevenueRange) {
          revenueRangeModel.value = {
            min: parseInt(newRevenueRange.low),
            max: parseInt(newRevenueRange.high)
          };
        }
      },
      { immediate: true }
    );

    const handleChangedTab = async newValue => {
      activeTab.value = newValue;
      activeTabIndex.value = tabs.value.findIndex(tab => tab.value === newValue);

      // Reset other filters and fetch new data
      searchQuery.value = '';
      selectedLocations.value = [];
      selectedServices.value = [];
      contentType.value = [];
      filters.value = {
        statuses: ['active', 'blocked', 'waiting', 'deleted'],
        role: newValue === 'CUSTOMERS' ? 'client' : 'photographer'
      };

      await updateFilters();
    };

    const editUser = user => {
      selectedUser.value = user;
      selectedUserId.value = user.id;
      if (user.role === 'client') {
        activeTab.value = 'CUSTOMERS';
        activeTabIndex.value = tabs.value.findIndex(tab => tab.value === 'CUSTOMERS');
        editComponent.value = EditClient;
      } else {
        activeTab.value = 'CREATORS';
        activeTabIndex.value = tabs.value.findIndex(tab => tab.value === 'CREATORS');
        editComponent.value = EditPhotographer;
      }
    };

    const goBack = () => {
      editComponent.value = null;
      selectedUser.value = null;
      selectedUserId.value = 0;

      // Ensure we're on the correct tab
      if (activeTab.value === 'CUSTOMERS') {
        activeTab.value = 'CUSTOMERS';
        activeTabIndex.value = tabs.value.findIndex(tab => tab.value === 'CUSTOMERS');
      } else {
        activeTab.value = 'CREATORS';
        activeTabIndex.value = tabs.value.findIndex(tab => tab.value === 'CREATORS');
      }

      // Optionally, you might want to refetch the data or update filters
      updateFilters();
    };
    const getStatusColor = status => {
      switch (status) {
        case 'active':
          return 'positive';
        case 'blocked':
          return 'negative';
        case 'deleted':
          return 'grey-7';
        case 'waiting':
          return 'warning';
        default:
          return 'grey';
      }
    };

    const capitalizeFirstLetter = string => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const updateUserMutation = useMutation({
      mutationFn: ({ id, payload }) => UserApi.updateUser({ id, payload }),
      onMutate: async variables => {
        await queryClient.cancelQueries({ queryKey: ['users', page, activeTab, filters] });
        const previousData = queryClient.getQueryData(['users', page, activeTab, filters]);

        if (previousData && previousData.users) {
          queryClient.setQueryData(['users', page, activeTab, filters], oldData => {
            return {
              ...oldData,
              users: oldData.users.map(user => {
                if (user.id === variables.id) {
                  // Update all properties of the user
                  return { ...user, ...variables.payload };
                }
                return user;
              })
            };
          });
        }

        return { previousData };
      },
      onError: (error, variables, context) => {
        if (context.previousData) {
          queryClient.setQueryData(['users', page, activeTab, filters], context.previousData);
        }
        store.dispatch('notification/addErrorNotification', 'Failed to update user');
      },
      onSuccess: () => {
        // Invalidate and refetch
        // queryClient.invalidateQueries(['users', page, activeTab, filters]);
        store.dispatch('notification/addSuccessNotification', 'User updated successfully');
      }
    });

    const updateUser = (id, payload) => {
      const targetRole = activeTab.value === 'CUSTOMERS' ? 'client' : 'photographer';
      const targetPayload = { ...payload, role: targetRole };
      return updateUserMutation.mutate({ id, payload: targetPayload });
    };
    const getButtonProps = user => {
      switch (user.status) {
        case 'waiting':
          return {
            icon: 'check_circle',
            tooltipText: 'Approve user',
            color: 'green',
            newStatus: 'active'
          };
        case 'active':
          return {
            icon: 'lock',
            tooltipText: 'Block user',
            color: 'red',
            newStatus: 'blocked'
          };
        case 'blocked':
          return {
            icon: 'lock_open',
            tooltipText: 'Activate user',
            color: 'blue',
            newStatus: 'active'
          };
        default:
          return {
            icon: 'help',
            tooltipText: 'Unknown status',
            color: 'grey-7',
            newStatus: null
          };
      }
    };

    const getButtonIcon = user => getButtonProps(user).icon;
    const getTooltipText = user => getButtonProps(user).tooltipText;
    const getButtonColor = user => getButtonProps(user).color;
    const getNewStatus = user => getButtonProps(user).newStatus;
    const isDisabled = user => user.status === 'deleted';

    const debouncedUpdateFilters = debounce(async () => {
      await updateFilters();
    }, 300); // 300ms delay

    const handleSearchInput = value => {
      // Remove any non-letter and non-space characters
      searchQuery.value = value.replace(/[^a-zA-Z\s]/g, '');
      debouncedUpdateFilters();
    };

    const handleRevenueRangeInput = value => {
      revenueRangeModel.value = value;
      debouncedUpdateFilters();
    };

    const onlyAllowLettersAndSpaces = event => {
      let char = String.fromCharCode(event.keyCode);
      if (/[^a-zA-Z\s]/.test(char)) {
        event.preventDefault();
      }
    };

    const getTopSixServices = shoots_per_service => {
      if (!shoots_per_service || !Array.isArray(shoots_per_service)) return [];
      return [...shoots_per_service].sort((a, b) => b.count - a.count).slice(0, 6);
    };

    onUnmounted(() => {
      debouncedUpdateFilters.cancel();
    });

    return {
      users,
      totalPages,
      total,
      currentPage: page,
      isLoading,
      isRefetching,
      activeTab,
      activeTabIndex,
      tabs,
      handleChangedTab,
      searchQuery,
      selectedServices,
      selectedLocations,
      contentType,
      serviceOptions,
      locationOptions,
      isLoadingLocations,
      updateFilters,
      editUser,
      selectedUser,
      selectedUserId,
      editComponent,
      getStatusColor,
      capitalizeFirstLetter,
      selectedStatuses,
      statusOptions,
      handleSearchInput,
      onlyAllowLettersAndSpaces,
      revenueRange,
      isLoadingRange,
      revenueRangeModel,
      updateUser,
      goBack,
      getTopSixServices,
      handleRevenueRangeInput,
      getButtonIcon,
      getTooltipText,
      getButtonColor,
      getNewStatus,
      isDisabled
    };
  }
};
</script>

<style scoped lang="scss">
.container {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 12px;
  position: relative;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr; /* Collapse the grid to a single column */
  }
}
.filters-container {
  width: 300px;
  height: calc(100vh - 180px); /* Adjust 120px to the combined height of other components */
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 18px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1024px) {
    display: none; /* Hide filters container on small screens */
  }
}

.users-container {
  width: 100%;
  height: calc(100vh - 180px); /* Adjust 120px to the combined height of other components */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  overflow-y: scroll;
  position: relative;
}
.pagination-container {
  position: absolute;
  bottom: 0;
  left: 0;
  left: 312px;
  width: calc(100vw - 392px);
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: #ffffff;
  z-index: 10000;
  & > span {
    @include inter-font($size: 14px, $bolded: false, $color: var(--primary-text-color));
    margin-left: auto;
  }
}

.user-card {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 400px;
  text-align: left;
  position: relative;
  padding-bottom: 70px;
}

.user-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.user-location {
  margin: 0;
  margin-bottom: 12px;
  border-bottom: 0.1px solid #666666;
  padding-bottom: 6px;

  & > span {
    margin-left: 6px;
  }
}

.user-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: 0.9rem;
}

.total-shoots {
  position: absolute;
  right: 24px;
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}

.service-chip {
  display: inline-block;
  border: 0.5px solid lightgrey;
  padding: 4px 12px;
  margin-right: 6px;
  margin-bottom: 6px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 20px;
  @include inter-font($size: 12px, $bolded: false, $color: black);
}

.card-footer {
  display: flex;
  padding: 12px 24px;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.range-labels {
  display: flex;
  justify-content: space-between;
}
.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
