<template>
  <div class="q-pa-md q-gutter-sm">
    <q-dialog v-model="isVisible" @hide="handleDialogHide">
      <q-card style="width: 400px !important">
        <q-card-section>
          <div class="text-h6">{{ header }}</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <p>{{ text }}</p>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="black" @click="cancelHandler" />
          <q-btn flat label="Confirm" color="black" @click="accept" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api';

export default {
  name: 'ConfirmDialog',
  props: {
    header: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    acceptHandler: {
      type: Function,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const isVisible = ref(props.visible);

    watch(
      () => props.visible,
      newValue => {
        isVisible.value = newValue;
      }
    );

    const cancelHandler = () => {
      isVisible.value = false;
      emit('update:visible', false); // Emit event to update parent's visible prop
    };

    const accept = () => {
      props.acceptHandler();
      isVisible.value = false;
      emit('update:visible', false); // Emit event to update parent's visible prop
    };
    // Handle dialog hide
    const handleDialogHide = () => {
      isVisible.value = false;
      emit('update:visible', false); // Synchronize visibility with the parent component
    };

    return {
      isVisible,
      cancelHandler,
      accept,
      handleDialogHide
    };
  }
};
</script>
