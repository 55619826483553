<template>
  <div class="roles-accordion">
    <q-list bordered>
      <!-- Admin Role -->
      <q-expansion-item group="rolesGroup" default-opened :header-style="{ height: '48px', backgroundColor: '#fefefa' }">
        <template v-slot:header>
          <q-item-section avatar>
            <q-icon color="#2C3E50" name="policy" size="md" />
          </q-item-section>

          <q-item-section class="header"> Admin Role </q-item-section>
        </template>

        <q-card>
          <q-card-section>
            <p class="card-text">As an Admin, you have full control over the team members and can manage various tasks related to shoots and requests.</p>
            <div class="chips-container">
              <q-chip v-for="action in adminActions" color="primary" text-color="#2C3E50" :key="action">{{ action }}</q-chip>
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-separator />

      <!-- Manager Role -->
      <q-expansion-item group="rolesGroup" :header-style="{ height: '48px', backgroundColor: '#fefefa' }">
        <template v-slot:header>
          <q-item-section avatar>
            <q-icon color="#2C3E50" name="policy" size="md" />
          </q-item-section>

          <q-item-section class="header"> Manager Role </q-item-section>
        </template>
        <q-card>
          <q-card-section>
            <p class="card-text">As a Manager, you can oversee shoot-related activities and handle scheduling and requests efficiently.</p>
            <div class="chips-container">
              <q-chip v-for="action in managerActions" color="primary" text-color="#2C3E50" :key="action">{{ action }}</q-chip>
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-separator />

      <!-- Viewer Role -->
      <q-expansion-item group="rolesGroup" :header-style="{ height: '48px', backgroundColor: '#fefefa' }">
        <template v-slot:header>
          <q-item-section avatar>
            <q-icon color="#2C3E50" name="policy" size="md" />
          </q-item-section>

          <q-item-section class="header"> Viewer Role </q-item-section>
        </template>
        <q-card>
          <q-card-section>
            <p class="card-text">As a Viewer, you have limited access to shoot-related content but can still view and download available resources.</p>
            <div class="chips-container">
              <q-chip v-for="action in viewerActions" color="primary" text-color="#2C3E50" :key="action">{{ action }}</q-chip>
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-list>
  </div>
</template>

<script>
export default {
  setup() {
    const adminActions = ['Manage team members', 'View shoots', 'Request to schedule a shoot', 'Redeem a shoot', 'Edit requests', 'Cancellation requests', 'Download content'];

    const managerActions = ['View shoots', 'Request to schedule a shoot', 'Redeem a shoot', 'Edit requests', 'Cancellation requests', 'Download content'];

    const viewerActions = ['View shoots', 'Download content'];

    return {
      adminActions,
      managerActions,
      viewerActions
    };
  }
};
</script>

<style lang="scss" scoped>
.roles-accordion {
  /* Add any additional styling here */
  width: 100%;
  border-radius: 10px !important;
}

.header {
  @include antonio-font($size: 18px, $uppercase: true, $color: var(--main-text-color));
  text-align: left;
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.card-text {
  text-align: left;
}
</style>
