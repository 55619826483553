<template>
  <div class="baseSlider">
    <span class="baseSlider__range mr-10">{{ min }}</span>
    <VueSlider
      :dot-size="14"
      :dot-style="{ 'background-color': '#151515', border: 'unset', 'box-shadow': 'none' }"
      hide-label
      :interval="step"
      marks
      :min="min"
      :max="max"
      :process-style="{ 'background-color': 'transparent' }"
      tooltip="none"
      :value="value"
      width="100%"
      @change="$emit('value:update', $event)"
    />
    <span class="baseSlider__range ml-10">{{ max }}</span>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

/**
 * Base components to show a box with a slider
 *
 * @displayName BaseSlider
 */
export default {
  name: 'BaseSlider',
  model: {
    event: 'value:update',
    prop: 'value'
  },
  components: {
    VueSlider
  },
  props: {
    /**
     * Max range value
     */
    max: { default: 10, type: Number },
    /**
     * Min range value
     */
    min: { default: 1, type: Number },
    /**
     * Set the slider step amount
     */
    step: { default: 1, type: Number },
    /**
     * Value of the slider
     */
    value: { default: null, type: Number }
  },
  watch: {
    value: {
      immediate: true,
      handler: async function () {
        await this.$nextTick();
        const ring = this.$el.querySelector('.vue-slider-dot-handle');

        ring.dataset.value = this.value;
      }
    }
  }
};
</script>

<style lang="scss">
.baseSlider {
  align-items: center;
  display: flex;

  &__range {
    @include inter-font($size: 10px);
  }

  .vue-slider-mark {
    background-color: #d8d8d8;
    border-radius: 50%;
    height: 6px !important;
    opacity: 1;
    width: 6px !important;
  }

  .vue-slider-mark-step {
    background-color: unset;
  }

  .vue-slider {
    height: 2px !important;
  }

  .vue-slider-dot-handle {
    &::after {
      @include inter-font($size: 10px, $color: var(--terziary-text-color));
      content: attr(data-value);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
</style>
