<template>
  <div class="container">
    <div class="row justify-center q-my-md" v-if="isLoading">
      <q-spinner-dots color="primary" size="150px" />
    </div>
    <div v-else class="content-container">
      <RolesAccordion class="side" />
      <div class="content">
        <div class="card">
          <p class="card-header">Great content is best experienced <span>together</span></p>
          <p class="card-text">
            Maximize the potential of your Flashy journey by assigning roles tailored to your team's needs. Whether you're an administrator overseeing operations, a manager orchestrating projects, or
            a viewer enjoying the content, Flashy provides the tools to enhance collaboration and streamline workflows
          </p>
          <BaseButton :icon="'users'" :label="'Invite people'" type="default" @click.native="handleInviteUser" :style="{ position: 'absolute', right: '24px' }" />
        </div>
        <div class="cards-grid">
          <div v-for="user in subClients" class="user-card" :key="user.id">
            <!-- Card Header -->
            <div style="padding: 12px">
              <div class="user-card-header">
                <q-avatar class="user-card-header-avatar" color="primary">
                  {{ user.name.split(' ')[0].charAt(0).toUpperCase() + user.name.split(' ')[1].charAt(0).toUpperCase() }}
                </q-avatar>
                <div class="user-card-header-data">
                  <p class="user-card-name">{{ user.name }}</p>
                  <p class="user-card-role">{{ user.subClientRole }}</p>
                </div>
              </div>
            </div>
            <!-- Card Stats -->
            <div class="card-stats">
              <div class="card-stat">
                <p class="card-stat-label">Shoots Redeemed</p>
                <p class="card-stat-value">{{ user.shoot_number }}</p>
              </div>
              <div class="card-stat">
                <p class="card-stat-label">Cumulative Spend</p>
                <p class="card-stat-value">{{ user.shoot_total_price_or_revenue }}</p>
              </div>
            </div>
            <!-- User Card Footer -->
            <div class="user-card-footer-spacer">
              <div class="user-card-footer">
                <div class="user-card-footer-status">
                  <q-badge :color="user.status === 'active' ? 'blue' : 'negative'" style="width: 16px; height: 16px; border-radius: 5px" />
                  <p class="user-card-footer-status-label">
                    {{ user.status === 'active' ? 'Active' : 'Locked' }}
                  </p>
                </div>
                <div class="user-card-footer-btns">
                  <BaseIcon icon="edit" class="user-card-footer-btn" stroke="#151515" :custom_style="{ width: '20px', height: '20px' }" tooltip="Edit user" @click.native="handleEditUser(user)" />
                  <BaseIcon
                    :icon="user.status === 'active' ? 'lock' : 'unlock'"
                    class="user-card-footer-btn"
                    stroke="#151515"
                    :custom_style="{ width: '20px', height: '20px' }"
                    :tooltip="user.status === 'active' ? 'Lock user' : 'Unlock user'"
                    @click.native="openConfirmDialog(user.status === 'active' ? 'lock' : 'unlock', { ...user, status: user.status === 'active' ? 'blocked' : 'active' })"
                  />
                  <BaseIcon
                    icon="trash"
                    class="user-card-footer-btn"
                    stroke="#151515"
                    :custom_style="{ width: '20px', height: '20px' }"
                    tooltip="Delete user"
                    @click.native="openConfirmDialog('delete', user)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Actions Confirmation Dialog -->
    <ConfirmDialog
      :header="confirmDialogHeader"
      :text="confirmDialogText"
      :visible="isConfirmDialogVisible"
      :acceptHandler="confirmDialogAcceptHandler"
      @update:visible="isConfirmDialogVisible = $event"
      :style="{ padding: '0', margin: '0' }"
    />

    <!-- Team Invitation Dialog -->
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <q-dialog v-model="showInviteDialog">
        <div class="dialog-container">
          <p class="header" v-if="selectedUser">edit <span>team </span>member details</p>
          <p class="header" v-else>Add a new <span>team </span>member</p>
          <p class="text" v-if="selectedUser">Update the information for your team member below.</p>
          <p class="text" v-else>Fill your new team member's information and add them into your team.</p>
          <q-btn icon="close" flat round @click="showInviteDialog = false" class="close" />

          <div class="grid">
            <div class="grid-item">
              <p class="label">First Name</p>
              <ValidationProvider name="First Name" rules="required" v-slot="{ errors }">
                <BaseInput v-model="userData.firstName" :error="errors.length > 0 ? true : false" :errorMessage="errors[0]" placeholder="Add person's first name" />
              </ValidationProvider>
            </div>

            <div class="grid-item">
              <p class="label">Last Name</p>
              <ValidationProvider name="Last Name" rules="required" v-slot="{ errors }">
                <BaseInput v-model="userData.lastName" :error="errors.length > 0 ? true : false" :errorMessage="errors[0]" placeholder="Add person's last name" />
              </ValidationProvider>
            </div>

            <div class="grid-item">
              <p class="label">Email Address</p>
              <ValidationProvider :name="'Email Address'" :rules="selectedUser ? '' : 'required|email'" v-slot="{ errors }">
                <BaseInput
                  v-model="userData.email"
                  :error="errors.length > 0 ? true : false"
                  :errorMessage="errors[0]"
                  :debounce="250"
                  placeholder="Add person's email"
                  :disabled="selectedUser ? true : false"
                />
              </ValidationProvider>
            </div>

            <div class="grid-item">
              <p class="label">Phone Number</p>
              <ValidationProvider name="Phone Number" rules="required|phone" v-slot="{ errors }">
                <BasePhoneNumber v-model="userData.phoneNumber" :error="errors.length > 0 ? true : false" :errorMessage="errors[0]" />
              </ValidationProvider>
            </div>
            <!-- Role in the team select input -->
            <div class="grid-item">
              <p class="label">Role in the team</p>
              <BaseSelect v-model="userData.role" :options="roleOptions" />
            </div>
          </div>
          <div class="footer">
            <p class="contact">For specific use cases, don't hesitate to <a href="https://www.getflashy.com/contact">contact us</a></p>
            <BaseButton
              type="default"
              :label="selectedUser ? 'Update user' : 'Add team member'"
              @click.native="selectedUser ? updateUser(selectedUser) : submitForm()"
              :disabled="invalid || isRequesting"
              :loading="isRequesting"
            />
          </div>
        </div>
      </q-dialog>
    </ValidationObserver>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { required, email } from 'vee-validate/dist/rules';
import { useQuery } from '@tanstack/vue-query';
import { UserApi } from '@api/index';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@base/BaseButton.vue';
import BaseIcon from '@base/BaseIcon.vue';
import BasePhoneNumber from '@/components/base/BasePhoneNumber.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import countriesHelper from '@utils/countries/countries';
import RolesAccordion from '@/components/RolesAccordion.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

// Extend the default rules
for (let [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation
  });
}

extend('required', {
  ...required,
  message: '{_field_} cannot be empty' // Generic message for all fields
});

extend('email', {
  ...email, // Keep the basic email format validation
  message: 'Please enter a valid email address',
  validate: async value => {
    if (!email.validate(value)) {
      // Use VeeValidate's email rule for format validation
      return 'Please enter a valid email address';
    }

    try {
      const { statusCode, data } = await UserApi.checkEmailExistence(value);
      if (statusCode === 200 && data) {
        return !data.checker || 'This email is already in use. Please use a different email.'; // Ensure this logic matches your API response
      }
    } catch (error) {
      console.error('Error validating email:', error);
      return 'Unable to validate email. Please try again later.';
    }
  }
});

// Custom phone validation rule
extend('phone', {
  validate(value) {
    const phoneNumberRegex = new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/);
    return phoneNumberRegex.test(value.replace(/\s/g, ''));
  },
  message: 'Invalid Phone Number'
});

export default {
  name: 'SubAccounts',
  components: {
    BaseInput,
    BasePhoneNumber,
    BaseButton,
    BaseSelect,
    RolesAccordion,
    ValidationProvider,
    ValidationObserver,
    ConfirmDialog,
    BaseIcon
  },
  setup(props, { root }) {
    const store = root.$store;
    const showInviteDialog = ref(false);
    const isRequesting = ref(false);

    // User data
    const userData = reactive({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      role: 'Manager' // Default value set to Viewer
    });

    // Role options
    const roleOptions = [
      { label: 'Manager', value: 'manager' },
      { label: 'Viewer', value: 'viewer' }
    ];

    // Business creation payload
    const payload = reactive({
      password: 'RandomStrongPassword',
      additionalInformations: {
        company_name: '',
        industry: '',
        country: '',
        contact_name: '',
        contact_email: '',
        contact_phone: '',
        main_location: ''
      },
      user: {
        name: '',
        email: '',
        phone: ''
      },
      paymentDetail: {
        type: 'test',
        details: {}
      }
    });

    const selectedUser = ref(null);

    const guid = ref(null);
    // Confirm Dialog
    const isConfirmDialogVisible = ref(false);
    const confirmDialogHeader = ref('');
    const confirmDialogText = ref('');
    const confirmDialogAcceptHandler = ref(() => {});

    const {
      data: subClients,
      isLoading,
      refetch
    } = useQuery({
      queryKey: ['subClients'],
      queryFn: async () => {
        const payload = {
          filters: {
            role: 'subClient',
            statuses: ['active', 'blocked', 'deleted', 'waiting']
          }
        };
        const response = await UserApi.searchUsers({ payload });
        if (response.statusCode === 200) {
          return response.data.users;
        } else {
          throw new Error('Failed to fetch sub-clients');
        }
      }
    });
    const user = computed(() => store.getters['user/getUser']);

    onMounted(async () => {
      const data = await UserApi.getUser({ id: user.value.id, role: 'client' });
      guid.value = data.data.user.registration_guid;
      const { statusCode, data: businessData } = guid.value && (await UserApi.getBusinessByGuid(guid.value));

      if (statusCode === 200) {
        const { company_name, country, industry, main_location } = businessData.user;
        const { label } = countriesHelper
          .getGroupedContries()
          .map(({ labels }) => labels)
          .flat()
          .find(({ value }) => value.toLowerCase() === country.toLowerCase());
        payload.additionalInformations.company_name = company_name;
        payload.additionalInformations.industry = industry;
        payload.additionalInformations.country = {
          label,
          value: country
        };
        payload.additionalInformations.main_location = main_location;
      } else {
        alert(`Failed to fetch business data: ${statusCode}`);
      }
    });

    function generateStrongPassword(length) {
      const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=?';
      let password = '';
      const passwordChecker = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');

      do {
        password = '';
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * charset.length);
          password += charset[randomIndex];
        }
      } while (!passwordChecker.test(password));

      return password;
    }

    function submitForm() {
      // Implementation for form submission
      // Validate before submitting
      isRequesting.value = true;
      this.$refs.observer.validate().then(async valid => {
        if (valid) {
          // Prepare Payload
          payload.user.name = userData.firstName;
          payload.user.surname = userData.lastName;
          payload.user.email = userData.email;
          payload.user.phone = userData.phoneNumber.replace(/\s/g, ''); // Remove spaces from phoneNumber
          payload.additionalInformations.contact_name = userData.firstName;
          payload.additionalInformations.contact_surname = userData.lastName;
          payload.additionalInformations.contact_email = userData.email;
          payload.additionalInformations.contact_phone = userData.phoneNumber.replace(/\s/g, '');

          // Generate a strong temporary password
          const strongPassword = generateStrongPassword(12); // You can adjust the length of the password as needed
          payload.password = strongPassword;

          // Create Business ( Sub Account )
          const business = await UserApi.createBusiness(payload, guid.value, false, userData.role.value || 'manager');
          if (business.statusCode === 200) {
            showInviteDialog.value = false;
            isRequesting.value = false;
            store.dispatch('notification/addSuccessNotification', 'New member has been added');
            refetch();
          } else {
            showInviteDialog.value = false;
            isRequesting.value = false;
            store.dispatch('notification/addFailureNotification', 'Failed add a new member');
          }
          userData.firstName = '';
          userData.lastName = '';
          userData.email = '';
          userData.phoneNumber = '';
          userData.role.value = 'Manager';
        } else {
          alert('Form is invalid!');
        }
      });
    }

    async function deleteUser(user) {
      try {
        const response = await UserApi.deleteSubClient(user.id);
        if (response.statusCode === 200) {
          store.dispatch('notification/addSuccessNotification', 'Account has been deleted');
          refetch();
        }
      } catch (error) {
        console.log(error);
      }
    }

    function handleInviteUser() {
      selectedUser.value = null;
      userData.firstName = '';
      userData.lastName = '';
      userData.email = '';
      userData.phoneNumber = '';
      userData.role = 'Manager';
      showInviteDialog.value = true;
    }

    async function handleEditUser(user) {
      // Set the user data
      selectedUser.value = user;
      userData.firstName = user.name.split(' ')[0];
      userData.lastName = user.name.split(' ')[1];
      userData.email = user.email;
      userData.phoneNumber = user.phone;
      userData.role = user.subClientRole === 'manager' ? 'Manager' : 'Viewer';
      showInviteDialog.value = true;
    }

    async function updateUser(user) {
      isRequesting.value = true;

      if (selectedUser.value) {
        // Set the payload
        user.contact_name = `${userData.firstName} ${userData.lastName}`;
        user.name = `${userData.firstName} ${userData.lastName}`;
        user.phone = userData.phoneNumber.replace(/\s/g, '');
        user.contact_phone = userData.phoneNumber.replace(/\s/g, '');
      }
      try {
        const response = await UserApi.updateUser({ id: user.id, payload: user });
        if (response.statusCode === 200) {
          // After successfully updating the user, check if the role needs to be updated
          if (selectedUser.value && userData.role.value !== user.subClientRole) {
            await updateSubClientRole({ id: user.id, subClientRole: userData.role.value });
          }
          // Wait for 750ms before calling refetch
          setTimeout(
            () => {
              isRequesting.value = false;
              if (selectedUser.value) {
                showInviteDialog.value = false;
                selectedUser.value = null;
              }
              refetch();
              store.dispatch('notification/addSuccessNotification', 'User has been updated');
            },
            selectedUser.value ? 750 : 0
          );
        }
      } catch (error) {
        console.log(error);
        isRequesting.value = false;
        if (selectedUser.value) {
          showInviteDialog.value = false;
          selectedUser.value = null;
        }
      }
    }

    async function updateSubClientRole({ id, subClientRole }) {
      try {
        const response = await UserApi.updateSubClientRole({ id, payload: { role: subClientRole } });
        if (response.statusCode === 200) {
          return response;
        }
      } catch (error) {
        console.log(error);
      }
    }

    function openConfirmDialog(action, user) {
      // Configure the dialog based on the action
      if (action === 'delete') {
        confirmDialogHeader.value = 'Delete user account';
        confirmDialogText.value =
          'Are you sure you want to delete this account? Please note, deleting this account will reassign all associated shoots to the main account. This action is irreversible.';
        confirmDialogAcceptHandler.value = () => deleteUser(user);
      } else if (action === 'lock') {
        confirmDialogHeader.value = 'Lock user account';
        confirmDialogText.value = 'While locked, the user will not be able to access their account or any associated data. Do you wish to proceed?';
        confirmDialogAcceptHandler.value = () => updateUser(user);
      } else if (action === 'unlock') {
        confirmDialogHeader.value = 'Unlock user account';
        confirmDialogText.value = 'You are about to unlock the account ,restoring their access to the account and all associated data. Are you sure you want to proceed?';
        confirmDialogAcceptHandler.value = () => updateUser(user);
      }

      // Open the dialog
      isConfirmDialogVisible.value = true;
    }

    return {
      subClients,
      isLoading,
      showInviteDialog,
      userData,
      submitForm,
      roleOptions,
      isRequesting,
      deleteUser,
      isConfirmDialogVisible,
      confirmDialogHeader,
      confirmDialogText,
      openConfirmDialog,
      confirmDialogAcceptHandler,
      updateSubClientRole,
      handleInviteUser,
      handleEditUser,
      updateUser,
      selectedUser
    };
  }
};
</script>

<style scoped lang="scss">
/* Dialog CSS  */
.dialog-container {
  min-width: 850px !important;
  min-height: 550px !important;
  position: relative;
  border-radius: 15px !important;
  background-color: #fefefa;
  padding: 24px;
  z-index: 100 !important;
  display: block;
}

.close {
  position: absolute;
  right: 24px;
  top: 24px;
}

.header {
  margin: 0 !important;
  @include antonio-font($size: 30px, $uppercase: true, $color: var(--main-text-color));
  & > span {
    color: var(--main-btn-color);
  }
}

.text {
  margin: 0 !important;
  margin-bottom: 36px !important;
  margin-top: 6px !important;
  @include inter-font($size: 18px, $color: var(--main-text-color));
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
}

.grid-item {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
}

.label {
  margin: 0;
  padding: 0;
  align-self: flex-start;
  margin-bottom: 8px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 24px;
  width: 100%;
  display: flex;
  align-items: center;
}

.contact {
  margin: 0;
  margin-right: auto;
  @include antonio-font($size: 16px, $uppercase: true, $color: var(--main-text-color));
  & > a {
    text-decoration: none;
    color: var(--main-btn-color);
  }
}
/* Dialog CSS  */

/* Content CSS */

.container {
  width: 100%;
  height: 100%;
  position: relative;
}
.content-container {
  position: relative;
  padding: 18px;
  height: 100%;
  width: 100%;
  display: flex;
  gap: 18px;
}

.side {
  width: 350px !important;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 18px;
  flex: 1;
}

.card {
  padding: 18px !important;
  background-color: #fefefa;
  border: 0.5px solid rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  text-align: left;
}
.card-header {
  @include antonio-font($size: 24px, $uppercase: true, $color: var(--main-text-color));
  margin: 0;
  padding: 0;
  & > span {
    color: var(--main-btn-color);
  }
}

.card-text {
  width: 75%;
  margin-top: 6px;
  font-size: 16px;
}

.cards-grid {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  gap: 18px;
  align-content: flex-start;
}

.user-card {
  display: flex;
  flex-direction: column;
  width: 325px !important;
  border-radius: 12px;
  background-color: #fefefa;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
}

/* Card Header */

.user-card-header {
  position: relative;
  display: flex;
  padding: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.user-card-header-avatar {
  width: 65px;
  height: 65px;
  color: var(--main-text-color);
}

.user-card-header-data {
  display: flex;
  flex-direction: column;
  height: 65px;
  text-align: left;
  margin-left: 12px;
  justify-content: center;
}

.user-card-name {
  font-size: 18px;
  font-weight: 400;
  @include antonio-font($size: 18px, $uppercase: true, $color: var(--main-text-color));
  color: var(--main-text-color);
  margin: 0;
  padding: 0;
}

.user-card-role {
  font-size: 14px;
  font-weight: 200;
  color: var(--main-text-color);
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}
/* Card Header */

/* Card Stats */

.card-stats {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 12px;
}

.card-stat {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 12px;
  background-color: #fefefa;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.card-stat-label {
  font-size: 14px;
  font-weight: 200;
  color: var(--main-text-color);
  margin: 0;
  padding: 0;
}

.card-stat-value {
  font-weight: 400;
  color: var(--main-text-color);
  font-size: 24px;
  margin: 0;
  padding: 0;
  margin-top: 6px;
}

/* Card Stats */

/* Card Footer */

/* Card Footer */

.user-card-footer-spacer {
  padding: 12px;
}

.user-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--main-btn-color);
  padding: 12px 18px;
  border-radius: 12px;
  margin-bottom: 6px;
}

.user-card-footer-status {
  display: flex;
  align-items: center;
  gap: 12px;
  align-items: center;
  justify-content: center;
}

.user-card-footer-btns {
  display: flex;
  border-radius: 12px;
  gap: 6px;
}

.user-card-footer-btn {
  cursor: pointer !important;
}

.user-card-footer-status-label {
  font-size: 14px;
  font-weight: 200;
  color: var(--main-text-color);
  margin: 0;
  padding: 0;
}
/* Content CSS */
</style>
