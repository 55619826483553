<template>
  <EditLayout
    :title="title"
    :confirm-label="getLabel"
    :back_icon="getBackIcon"
    :confirm_icon="getConfirmIcon"
    @go-back="$emit('go-back')"
    @positive:action="$emit('update:user')"
    @update:title="$emit('update:title', $event)"
  >
    <div class="editUserLayout__column">
      <BaseInput class="editUserLayout__title mb-30 pa-0" :value="title" borderless :clearable="false" @update:value="$emit('update:title', $event)" />
      <slot name="left-column" />
    </div>
    <div class="editUserLayout__column ml-40 mt-70">
      <slot name="middle-column" />
    </div>
    <div class="editUserLayout__column ml-40 mt-70">
      <slot name="right-column" />
    </div>
    <template #footerActions>
      <BaseButton
        v-if="showDelete && !isUserWaiting"
        class="mr-15"
        :icon="dangerIcon"
        :icon_style="{ height: '16px', width: '16px' }"
        :label="dangerLabel"
        type="danger"
        @click.native="handleDanger"
      />
      <slot name="footer-actions" />
      <DeleteUser :show="deleteDialog" :user="user" @dialog:closed="deleteDialog = null" @go-back="$emit('go-back')" @user:delete="deleteUser" />
    </template>
  </EditLayout>
</template>

<script>
// Api
import { UserApi } from '@api/index';

// Base Components
import BaseButton from '@base/BaseButton.vue';
import BaseInput from '@base/BaseInput.vue';

// Common Components
import EditLayout from '@common/EditLayout.vue';

// Components
import DeleteUser from '@components/accounts/DeleteUser.vue';

/**
 * Component used to edit a user
 */
export default {
  name: 'EditUserLayout',
  model: {
    event: 'update:title',
    prop: 'title'
  },
  components: {
    BaseButton,
    BaseInput,
    DeleteUser,
    EditLayout
  },
  props: {
    /**
     * Set to true to show delete
     */
    showDelete: { default: false, type: Boolean },
    /**
     * User name
     */
    title: { default: '', type: String },
    /**
     * User
     */
    user: { default: () => ({}), type: Object },
    showDisableDialog: { default: false, type: Boolean }
  },
  data() {
    return {
      deleteDialog: false
    };
  },
  computed: {
    /**
     * Return the icon based on the status
     * if 'active' return trash icon
     * if 'deleted' return refresh icon
     */
    dangerIcon() {
      const icon = {
        active: 'trash',
        blocked: 'trash',
        deleted: 'restore'
      };

      return icon[this.user.status];
    },
    /**
     * Return the label based on the status
     * if 'active' return delete
     * if 'deleted' return activate
     */
    dangerLabel() {
      const label = {
        active: this.$t('DELETE'),
        blocked: this.$t('DELETE'),
        deleted: this.$t('ACTIVATE')
      };

      return label[this.user.status];
    },

    getLabel() {
      return screen.width <= 760 ? this.$t('CONFIRM') : this.$t('CONFIRM_CHANGES');
    },
    getConfirmIcon() {
      return screen.width <= 760 ? '' : 'check';
    },
    getBackIcon() {
      return screen.width <= 760 ? '' : 'arrow-left';
    },
    /**
     * Returns true if user is waiting for approval
     */
    isUserWaiting() {
      return this.user.status === 'waiting';
    }
  },
  methods: {
    /**
     * Deleting user
     */
    async deleteUser() {
      this.deleteDialog = false;

      const payload = {
        ...this.user,
        status: 'deleted'
      };

      const deleteUser = await UserApi.updateUser({ id: this.user.id, payload });

      if (deleteUser.statusCode === 200) {
        this.$store.dispatch('notification/addSuccessNotification', this.$t('DELETE_USER_COMPLETED'));
        this.$emit('go-back');
      }
    },
    /**
     * Reactivating a deleted account
     */
    async handleActivate() {
      const payload = {
        ...this.user,
        status: 'active'
      };

      const activateUser = await UserApi.updateUser({ id: this.user.id, payload });

      if (activateUser.statusCode === 200) {
        this.$store.dispatch('notification/addSuccessNotification', this.$t('NOTIFICATIONS.USER.ACTIVATE'));
        this.$emit('go-back');
      }
    },
    /**
     * Handling danger button click
     */
    handleDanger() {
      const action = {
        active: () => this.handleDelete(),
        blocked: () => this.handleDelete(),
        deleted: () => this.handleActivate()
      };

      action[this.user.status]();
    },
    /**
     * Showing delete modal
     */
    handleDelete() {
      this.deleteDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.editUserLayout {
  &__column {
    flex: 1;
  }

  &__title {
    @include antonio-font($size: 20px);
    border-bottom: 2px solid var(--main-text-color);
    height: 40px;
    max-width: 300px;
    text-align: left;

    .q-field__control {
      padding: 0;
    }
  }
}
</style>
