<template>
  <q-table :data="flattenedCustomers" :columns="columns" row-key="uniqueId" :loading="isLoading" :rows-per-page-options="[50, 100, 150]" flat>
    <template v-slot:body="props">
      <q-tr :props="props" :class="{ 'sub-client-row': props.row.isSubClient }">
        <q-td v-for="col in props.cols" :key="col.name" :props="props" class="text-left">
          <template v-if="col.name === 'status'">
            <div class="row items-center">
              <div :class="['status-indicator', `bg-${getStatusColor(props.row.status)}`]"></div>
              <span class="q-ml-sm">{{ capitalizeFirstLetter(props.row.status) }}</span>
            </div>
          </template>
          <template v-else-if="col.name === 'actions'">
            <div class="row items-center q-gutter-sm">
              <q-btn round textColor="gray-7" flat icon="edit" :disabled="props.row.status === 'deleted'" @click="editUser(props.row)" size="xs">
                <q-tooltip>Edit user</q-tooltip>
              </q-btn>
              <q-btn round icon="delete" flat textColor="grey-7" :disabled="props.row.status === 'deleted'" @click="updateUserStatus(props.row, 'deleted')" size="xs">
                <q-tooltip>Soft delete user</q-tooltip>
              </q-btn>
              <q-btn
                flat
                round
                textColor="grey-7"
                :icon="props.row.status === 'blocked' ? 'lock_open' : 'lock'"
                @click="updateUserStatus(props.row, props.row.status === 'active' ? 'blocked' : 'active')"
                :disabled="props.row.status === 'deleted'"
                size="xs"
              >
                <q-tooltip>{{ props.row.status === 'blocked' ? 'Activate user' : 'Block user' }}</q-tooltip>
              </q-btn>
            </div>
          </template>
          <template v-else-if="col.name === 'main_location'">
            <q-tooltip>{{ col.value }}</q-tooltip>
            {{ truncateLocation(col.value) }}
          </template>
          <template v-else-if="col.name === 'company_name'">
            <q-tooltip>{{ col.value }}</q-tooltip>
            <span v-if="props.row.isSubClient" class="sub-client-indicator">↳</span>
            <p style="margin: 0; padding: 0; overflow: hidden; text-overflow: ellipsis">
              {{ col.value }}
            </p>
          </template>
          <template v-else>
            <span v-if="props.row.isSubClient && col.name === 'company_name'" class="sub-client-indicator">↳</span>
            {{ col.value }}
          </template>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api';

export default {
  name: 'CustomersTable',
  props: {
    customers: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    capitalizeFirstLetter: {
      type: Function,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const columns = ref([
      { name: 'client_id', align: 'left', label: 'Id', field: 'client_id', sortable: true },
      {
        name: 'company_name',
        align: 'left',
        label: 'Company',
        field: 'company_name',
        sortable: true,
        style: 'width: 200px; max-width: 200px;' // Adjust the width as needed
      },
      { name: 'name', align: 'left', label: 'Customer', field: 'name', sortable: true },
      { name: 'shoot_number', align: 'left', label: 'Shoots', field: 'shoot_number', sortable: true },
      { name: 'country', align: 'left', label: 'Country', field: 'country', sortable: true },
      {
        name: 'shoot_total_price_or_revenue',
        align: 'left',
        label: 'Revenue',
        field: 'shoot_total_price_or_revenue',
        sortable: true,
        format: val => `${val}`
      },
      { name: 'status', align: 'left', label: 'Status', field: 'status', sortable: true },
      { name: 'actions', align: 'left', label: 'Actions', field: 'actions', sortable: false }
    ]);

    const flattenedCustomers = computed(() => {
      return props.customers.reduce((acc, customer) => {
        acc.push({ ...customer, uniqueId: `main-${customer.id}` });
        if (customer.subclients && customer.subclients.length) {
          customer.subclients.forEach(subclient => {
            acc.push({ ...subclient, isSubClient: true, uniqueId: `sub-${subclient.id}` });
          });
        }
        return acc;
      }, []);
    });

    const editUser = user => {
      emit('edit-user', user);
    };

    const updateUserStatus = (user, newStatus) => {
      emit('update-user-status', user.id, { ...user, status: newStatus });
    };

    const getStatusColor = status => {
      switch (status) {
        case 'active':
          return 'positive';
        case 'blocked':
          return 'negative';
        case 'deleted':
          return 'grey-7';
        case 'waiting':
          return 'warning';
        default:
          return 'grey';
      }
    };

    const pagination = ref({
      sortBy: 'desc',
      descending: false,
      page: 1,
      rowsPerPage: 50,
      rowsNumber: props.totalPages * 50
    });

    const truncateLocation = location => {
      if (!location) return '';
      const words = location.split(' ');
      if (words.length <= 2) return location;
      return words.slice(0, 2).join(' ') + '...';
    };

    const getCellStyle = colName => {
      const baseStyle = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      };

      if (colName === 'company_name') {
        return {
          ...baseStyle,
          width: '200px',
          maxWidth: '200px'
        };
      }

      return baseStyle;
    };

    return {
      columns,
      flattenedCustomers,
      editUser,
      updateUserStatus,
      getStatusColor,
      pagination,
      truncateLocation,
      getCellStyle
    };
  }
};
</script>

<style lang="scss" scoped>
.q-table__container {
  height: calc(100vh - 180px);
  width: 100%;
}

.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.text-left {
  text-align: left;
}

// Define status colors
.bg-positive {
  background-color: #21ba45;
}
.bg-negative {
  background-color: #c10015;
}
.bg-warning {
  background-color: #f2c037;
}
.bg-grey-7 {
  background-color: #616161;
}
.bg-grey {
  background-color: #9e9e9e;
}

.sub-client-row {
  background-color: #f5f5f5;
  font-style: italic;
}

.sub-client-indicator {
  margin-right: 5px;
  color: #666;
}
</style>
