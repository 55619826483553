<template>
  <EditUserLayout
    v-model="newUser.name"
    show-delete
    :showDisableDialog="deleteDialog"
    :user="newUser"
    @go-back="$emit('go-back')"
    @update:user="updateClient"
    @confirmUpdate:user="confirmUpdate"
    @close="deleteDialog = false"
  >
    <template #left-column>
      <p class="editClient__title">{{ $t('COMPANY.COMPANY_INFO') }}</p>
      <BaseInput v-model="newUser.company_name" class="fs-12" :label="$t('COMPANY.NAME')" />
      <BaseSelect mandatory v-model="newUser.industry" :label="$t('COMPANY.INDUSTRY')" :options="industryOptions" />
      <BaseSelectWithFilter mandatory useInput :emitValue="false" :value="country" @update:value="$set(newUser, 'country', $event.value)" :label="$t('COMPANY.COUNTRY')" :options="countryOptions" />
      <BaseLocation
        v-model="newUser.main_location"
        mandatory
        :style="{ 'background-color': '#ffffff', height: '40px', padding: '6px 12px', 'font-size': '14px' }"
        :placeholder="$t('COMPANY.MAIN_LOCATION')"
        :country="newUser.country"
        :key="newUser.country"
      />
    </template>
    <template #middle-column>
      <p class="editClient__title">{{ $t('GENERAL_INFORMATION') }}</p>
      <BaseInput v-model="newUser.email" disabled class="fs-12" :label="$t('PERSONAL_DATA.EMAIL')" />
    </template>
    <template #right-column>
      <p class="editClient__title">{{ $t('CONTACT_INFO') }}</p>
      <BaseInput v-model="newUser.contact_name" class="fs-12" :label="$t('PERSONAL_DATA.NAME')" />
      <BaseInput v-model="newUser.contact_email" class="fs-12" :label="$t('PERSONAL_DATA.EMAIL')" />
      <BaseInput v-model="newUser.contact_phone" class="fs-12" :label="$t('PERSONAL_DATA.PHONE')" />
    </template>
  </EditUserLayout>
</template>

<script>
// Api
import { UserApi } from '@api/index';

// Base Components
// import BaseCalendarInput from '@base/BaseCalendarInput.vue';
// import BaseIcon from '@base/BaseIcon.vue';
import BaseInput from '@base/BaseInput.vue';
import BaseLocation from '@base/BaseLocation.vue';
import BaseSelect from '@base/BaseSelect.vue';
import BaseSelectWithFilter from '@base/BaseSelectWithFilter.vue';

// Common Components
import EditUserLayout from '@components/accounts/EditUserLayout.vue';

// Utils
import countriesHelper from '@utils/countries/countries';

/**
 * Component used to edit a user
 */
export default {
  name: 'EditClient',
  components: {
    BaseInput,
    EditUserLayout,
    BaseLocation,
    BaseSelect,
    BaseSelectWithFilter
  },
  props: {
    /**
     * get the selected user Id
     */
    selectedUserId: { default: null, type: Number },
    /**
     * User to be edited
     */
    user: { default: () => ({}), type: Object }
  },
  data() {
    return {
      countryOptions: countriesHelper.getGroupedContries(),
      industryOptions: [
        this.$t('INDUSTRY_LIST.AGRICULTURE'),
        this.$t('INDUSTRY_LIST.CONSTRUCTION'),
        this.$t('INDUSTRY_LIST.CORPORATE'),
        this.$t('INDUSTRY_LIST.E-COMMERCE'),
        this.$t('INDUSTRY_LIST.EDUCATION'),
        this.$t('INDUSTRY_LIST.EVENTS_WEDDINGS'),
        this.$t('INDUSTRY_LIST.FOOD_DELIVERY'),
        this.$t('INDUSTRY_LIST.FOOD_PRODUCTION'),
        this.$t('INDUSTRY_LIST.FOOD_TECH'),
        this.$t('INDUSTRY_LIST.GOVERNMENT'),
        this.$t('INDUSTRY_LIST.HEALTHCARE'),
        this.$t('INDUSTRY_LIST.HOSPITALITY'),
        this.$t('INDUSTRY_LIST.MANIFACTURING'),
        this.$t('INDUSTRY_LIST.PUBLIC_ADMINISTRATION'),
        this.$t('INDUSTRY_LIST.REAL_ESTATE'),
        this.$t('INDUSTRY_LIST.SOCIAL_MEDIA'),
        this.$t('INDUSTRY_LIST.SPORTS_FITNESS'),
        this.$t('INDUSTRY_LIST.TRANSPORTATION'),
        this.$t('INDUSTRY_LIST.WHOLESALE'),
        this.$t('INDUSTRY_LIST.INDIVIDUAL'),
        this.$t('INDUSTRY_LIST.OTHER')
      ],
      newUser: {},
      deleteDialog: false
    };
  },
  computed: {
    /**
     * Returns flattened country items
     */
    countryFlattened() {
      const labelsList = this.countryOptions.map(({ labels }) => labels);
      const flattenedLabelsList = labelsList.flat();

      return flattenedLabelsList;
    },
    /**
     * Retrieving country item
     */
    country() {
      return this.countryFlattened.find(({ value }) => value.toLowerCase() === this.newUser.country.toLowerCase());
    }
  },
  created() {
    this.newUser = Object.assign({}, this.user);
  },
  methods: {
    /**
     * Method used to update and save on BE the new user info
     */
    updateClient() {
      this.confirmUpdate();
    },

    async confirmUpdate() {
      this.$emit('update-user', this.newUser.id, this.newUser);
    }
  }
};
</script>

<style lang="scss" scoped>
.editClient {
  &__title {
    @include inter-font($size: 16px, $bolded: true);
    text-align: left;
  }
}
</style>
