import { render, staticRenderFns } from "./SubAccounts.vue?vue&type=template&id=33e49c49&scoped=true&"
import script from "./SubAccounts.vue?vue&type=script&lang=js&"
export * from "./SubAccounts.vue?vue&type=script&lang=js&"
import style0 from "./SubAccounts.vue?vue&type=style&index=0&id=33e49c49&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33e49c49",
  null
  
)

export default component.exports
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpinnerDots,QAvatar,QBadge,QDialog,QBtn});
