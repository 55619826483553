<template>
  <div class="editLayout full-height">
    <section class="editLayout__body">
      <div class="editLayout__columns">
        <slot />
      </div>
    </section>
    <section class="editLayout__footer">
      <BaseButton class="editLayout__button" :icon_style="{ height: '12px', width: '8px' }" :icon="back_icon" :label="$t('BACK')" @click.native="$emit('go-back')" />
      <div>
        <slot name="footerActions" />
        <BaseButton data-type="default" :icon="confirm_icon" :label="confirmLabel" @click.native="$emit('positive:action')" />
      </div>
    </section>
  </div>
</template>

<script>
// Base Components
import BaseButton from '@base/BaseButton.vue';

/**
 * Component used to create a base edit page layout
 */
export default {
  name: 'EditLayout',
  components: {
    BaseButton
  },
  model: {
    event: 'update:title',
    prop: 'title'
  },
  props: {
    back_icon: { default: '', type: String },
    /**
     * Confirm button icon
     */
    confirm_icon: { default: '', type: String },
    /**
     * Confirm action label
     */
    confirmLabel: { default: () => this.$t('CONFIRM'), type: String },
    /**
     * Item title
     */
    title: { default: '', type: String }
  }
};
</script>

<style lang="scss" scoped>
.editLayout {
  $horizontal-padding: 171px;

  display: flex;
  flex-direction: column;

  &__body {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    padding: 37px $horizontal-padding 49px $horizontal-padding;
    @include responsive($max: md) {
      padding: 30px;
      padding-top: 0;
    }
  }

  &__columns {
    display: flex;
    flex: 1;
    @include responsive($max: md) {
      flex-direction: column;
      > .editUserLayout__column {
        margin: 0;
        margin-top: 20px;
      }
    }
  }

  &__footer {
    align-items: center;
    background-color: var(--secondary-bg-color);
    display: flex;
    justify-content: space-between;
    min-height: 104px;
    padding: 0 $horizontal-padding;
    @include responsive($max: md) {
      padding: 0 30px;
    }
  }
  &__button {
    @include responsive($max: md) {
    }
  }
}
</style>
