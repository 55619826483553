<template>
  <q-dialog ref="dialog" @hide="handleClose">
    <q-card class="addSpecialization__card">
      <q-card-section class="row items-center pa-0">
        <p class="addSpecialization__title">{{ title || '-' }}</p>
        <q-space />
        <BaseIcon icon="close" v-close-popup />
      </q-card-section>

      <q-card-section class="pa-0 mt-36">
        <p class="addSpecialization__heading mb-35">{{ $t('SPECIALIZATION') }}:</p>
        <div class="addSpecialization__body">
          <section class="addSpecialization__services">
            <div v-for="service of filteredServices" :key="service.id" class="addSpecialization__service">
              {{ service.name }}
              <BaseIcon :icon="getIcon(service.id)" :style="{ height: '15px', 'stroke-width': '2px', width: '15px' }" @click.native="addSpecialization(service.id)" />
            </div>
          </section>
          <section class="addSpecialization__specializations ml-40">
            <div v-for="specialization of allSpecializations" :key="specialization" class="addSpecialization__specialization">
              <p>{{ serviceName(specialization) }}</p>
              <BaseIcon
                v-if="newSpecializations.includes(+specialization)"
                icon="close"
                :stroke="getCssVariable('--secondary-text-color')"
                :style="{ height: '15px', 'stroke-width': '2px', width: '15px' }"
                @click.native="removeSpecialization(specialization)"
              />
            </div>
          </section>
        </div>
        <hr data-theme="extra-light" class="hr mt-34" />
        <div class="addSpecialization__footer mt-20">
          <BaseButton type="default" :disabled="isConfirmDisabled" :label="$t('CONFIRM')" @click.native="confirmNewSpecializations" />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

// Base Components
import BaseButton from '@base/BaseButton.vue';
import BaseIcon from '@base/BaseIcon.vue';

/**
 * Component that shows a dialog with functionality to select a new specialization for the user
 */
export default {
  name: 'AddSpecialization',
  components: {
    BaseButton,
    BaseIcon
  },
  props: {
    /**
     * Set to true to show the dialog
     */
    show: { default: false, type: Boolean },
    /**
     * List of user current specializations (ids)
     */
    specializations: { default: () => [], type: Array },
    /**
     * Title
     */
    title: { default: '', type: String }
  },
  data() {
    return {
      newSpecializations: []
    };
  },
  computed: {
    ...mapGetters({
      services: 'services/getServices'
    }),
    /**
     * Returnig the list of specializations by merging the prop array and the onces added in the dialog
     */
    allSpecializations() {
      return [...this.specializations.map(item => +item), ...this.newSpecializations];
    },
    /**
     * List of available services filtered with service already being added as specialization
     */
    filteredServices() {
      if (screen.width <= 760) return this.services;
      return this.services.filter(({ id }) => this.allSpecializations.indexOf(id) === -1);
    },
    /**
     * Returns true if no change has been made
     */
    isConfirmDisabled() {
      return this.newSpecializations.length === 0;
    }
  },
  watch: {
    /**
     * When true show the dilaog, when false hide it
     */
    show() {
      if (this.show) {
        this.$refs.dialog.show();
      } else {
        this.$refs.dialog.hide();
      }
    }
  },
  methods: {
    /**
     * Method used to add a specialization
     */
    addSpecialization(id) {
      if (screen.width <= 760) {
        this.newSpecializations = this.updateArray({ array: this.newSpecializations, element: id });
      } else this.newSpecializations.push(id);
    },
    /**
     * Method used to emit an event to confirm the ne specializations, then closes the dialog
     */
    confirmNewSpecializations() {
      this.$emit('specializations:update', this.newSpecializations);
      this.newSpecializations = [];
      this.$refs.dialog.hide();
    },
    /**
     * used to get the correct icon to show when select categories;
     */
    getIcon(id) {
      if (screen.width <= 760) return this.allSpecializations.includes(id) ? 'check' : 'plus';
      return 'plus';
    },
    /**
     * Method used to handle the closing of the dialog by emitting an event
     */
    handleClose() {
      this.$emit('dialog:closed');
    },
    /**
     * Method used to remove a specialization from the list
     */
    removeSpecialization(specialization) {
      this.newSpecializations = this.updateArray({ array: this.newSpecializations, element: specialization });
    },
    /**
     * Returning service name based on id
     */
    serviceName(_id) {
      const service = this.services.find(({ id }) => +id === +_id) || {};

      return service.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.addSpecialization {
  &__body {
    display: flex;

    > section {
      flex: 50%;
    }
  }

  &__card {
    border-radius: 10px;
    padding: 35px 40px;
    min-width: 670px;
    @include responsive($max: md) {
      height: 100vh;
      max-height: 100% !important;
      min-width: 100%;
    }
  }

  &__footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  &__heading {
    @include antonio-font($size: 23px);
  }

  &__service {
    @include inter-font($size: 14px);
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 4px 12px;
    @include responsive($max: md) {
      border-bottom: 1px solid var(--grey-hr);
      border-radius: unset;
      padding: 15px 0;
    }

    .baseIcon {
      display: none;
      @include responsive($max: md) {
        display: block !important;
      }
    }

    &:hover {
      background-color: var(--main-bg-color);

      .baseIcon {
        display: block;
      }
    }
  }

  &__specialization {
    align-items: center;
    display: flex;
    justify-content: space-between;

    > p {
      @include inter-font($size: 14px, $color: var(--secondary-text-color));
      margin-bottom: 0;
    }

    .baseIcon {
      display: none;
    }

    &:hover {
      .baseIcon {
        display: block;
      }
    }
  }

  &__specializations {
    background-color: var(--main-bg-color);
    border-radius: 10px;
    min-height: 158px;
    padding: 9px 13px;
    @include responsive($max: md) {
      display: none;
    }
  }

  &__title {
    @include antonio-font($size: 14px);
  }
}
</style>
